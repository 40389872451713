import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddStreamResult = {
  __typename?: 'AddStreamResult';
  archiveId?: Maybe<Scalars['String']>;
};

export type AppSettings = {
  __typename?: 'AppSettings';
  defaultLogoutTimeout?: Maybe<Scalars['Int']>;
  defaultRemoteAccess?: Maybe<Scalars['Boolean']>;
  instructorLogoutTimeout?: Maybe<Scalars['Int']>;
  studentLogoutTimeout?: Maybe<Scalars['Int']>;
};

export type BackgroundImage = {
  __typename?: 'BackgroundImage';
  User?: Maybe<User>;
  centerCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  imgName?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  uploadedBy?: Maybe<Scalars['String']>;
};

export type BackgroundImageInput = {
  centerCode?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  imgName?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type BackgroundImagesFilter = {
  centerCode?: InputMaybe<Scalars['String']>;
  classSlug?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type BulkCenterInput = {
  centerCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type BulkCenterResult = {
  __typename?: 'BulkCenterResult';
  centerCode?: Maybe<Scalars['String']>;
};

export type BulkCreateCentersResult = {
  __typename?: 'BulkCreateCentersResult';
  createdCenters?: Maybe<Array<Maybe<BulkCenterResult>>>;
  result?: Maybe<Scalars['String']>;
};

export type BulkCreateUsersResult = {
  __typename?: 'BulkCreateUsersResult';
  createdUsers?: Maybe<Array<Maybe<BulkUserResult>>>;
  result?: Maybe<Scalars['String']>;
};

export type BulkUserInput = {
  centerCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type BulkUserResult = {
  __typename?: 'BulkUserResult';
  uuid?: Maybe<Scalars['String']>;
};

export type Center = {
  __typename?: 'Center';
  centerCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
};

export type CenterWithClasses = {
  __typename?: 'CenterWithClasses';
  Classes?: Maybe<Array<Maybe<Class>>>;
  centerCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type CenterWithRegion = {
  __typename?: 'CenterWithRegion';
  Region?: Maybe<Region>;
  centerCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type CenterWithUsers = {
  __typename?: 'CenterWithUsers';
  Users?: Maybe<Array<Maybe<User>>>;
  centerCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Class = {
  __typename?: 'Class';
  centerCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vonage_session?: Maybe<Scalars['String']>;
};

export type ClassUser = {
  __typename?: 'ClassUser';
  Connections?: Maybe<Array<Maybe<User>>>;
  classSlug?: Maybe<Scalars['String']>;
  connectionStatus?: Maybe<Scalars['Int']>;
  handRaised?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inactive?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userMode?: Maybe<Scalars['String']>;
};

export type ClassUserInput = {
  classSlug?: InputMaybe<Scalars['String']>;
  connectionStatus?: InputMaybe<Scalars['Int']>;
  handRaised?: InputMaybe<Scalars['Boolean']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userMode?: InputMaybe<Scalars['String']>;
};

export type ClassUserSession = {
  __typename?: 'ClassUserSession';
  classSlug?: Maybe<Scalars['String']>;
  connectedAt?: Maybe<Scalars['DateTime']>;
  connectionId?: Maybe<Scalars['String']>;
  disconnectedAt?: Maybe<Scalars['DateTime']>;
  sessionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClassUserSessionInput = {
  classSlug?: InputMaybe<Scalars['String']>;
  connectedAt?: InputMaybe<Scalars['DateTime']>;
  connectionId?: InputMaybe<Scalars['String']>;
  disconnectedAt?: InputMaybe<Scalars['DateTime']>;
  sessionId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ClassUserUpdateConnectionInput = {
  classSlug?: InputMaybe<Scalars['String']>;
  connected?: InputMaybe<Scalars['Boolean']>;
  connectedTo?: InputMaybe<Scalars['String']>;
  ghost?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ClassUserUpdateInput = {
  classSlug?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isMobile?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userMode?: InputMaybe<Scalars['String']>;
};

export type ClassUserWithProfile = {
  __typename?: 'ClassUserWithProfile';
  Connections?: Maybe<Array<Maybe<User>>>;
  User?: Maybe<User>;
  classSlug?: Maybe<Scalars['String']>;
  connectionStatus?: Maybe<Scalars['Int']>;
  handRaised?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inactive?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userMode?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ClassWhithRegion = {
  __typename?: 'ClassWhithRegion';
  centerCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionClassUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vonage_session?: Maybe<Scalars['String']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type DeviceInfoInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  selected?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  classSlug?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<Maybe<GroupUser>>>;
  ownerId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  whiteBoardId?: Maybe<Scalars['Int']>;
};

export type GroupInput = {
  classSlug?: InputMaybe<Scalars['String']>;
  groupName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  whiteBoardId?: InputMaybe<Scalars['Int']>;
};

export type GroupSubscriptionInput = {
  classSlug: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type GroupUser = {
  __typename?: 'GroupUser';
  GroupUserStatus?: Maybe<GroupUserStatus>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
};

export type GroupUserInput = {
  groupId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type GroupUserStatus = {
  __typename?: 'GroupUserStatus';
  status?: Maybe<Scalars['String']>;
};

export type Key = {
  __typename?: 'Key';
  expiresAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
};

export type LimitedUser = {
  __typename?: 'LimitedUser';
  centerCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  availableClasses?: Maybe<Array<Maybe<ClassWhithRegion>>>;
  backendContainerIp?: Maybe<Scalars['String']>;
  centerCode?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tokenExp?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserCenter?: Maybe<Scalars['String']>;
  bulkCreateCenters?: Maybe<BulkCreateCentersResult>;
  bulkCreateUsers?: Maybe<BulkCreateUsersResult>;
  closeGroup?: Maybe<Group>;
  createCenter?: Maybe<Center>;
  createClass?: Maybe<Class>;
  createClassUserSession?: Maybe<ClassUserSession>;
  createOrUpdateWhiteBoard?: Maybe<WhiteBoard>;
  createRecording?: Maybe<Recording>;
  createUser?: Maybe<User>;
  createWhiteBoardServer?: Maybe<WhiteBoardServer>;
  deleteBackgroundImage?: Maybe<WhiteBoardDeleteResult>;
  deleteCenter?: Maybe<Scalars['String']>;
  deleteClass?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['String']>;
  deleteWhiteBoard?: Maybe<WhiteBoard>;
  deleteWhiteBoardPDF?: Maybe<WhiteBoardDeleteResult>;
  deleteWhiteBoardServer?: Maybe<WhiteBoardServerDeleteResult>;
  endClass?: Maybe<Scalars['String']>;
  generateTempToken?: Maybe<Scalars['String']>;
  generateTestToken?: Maybe<TestToken>;
  joinGroup?: Maybe<Group>;
  leaveGroup?: Maybe<Group>;
  makeActiveUserCenter?: Maybe<LoginResult>;
  removeUserCenter?: Maybe<Scalars['String']>;
  removeUserConnections?: Maybe<Scalars['String']>;
  resetPassword?: Maybe<User>;
  resetToCustomPassword?: Maybe<User>;
  setDefaultImage?: Maybe<WhiteBoardDeleteResult>;
  startClass?: Maybe<Class>;
  updateCenter?: Maybe<Center>;
  updateClassUser?: Maybe<ClassUser>;
  updateClassUserConnection?: Maybe<ClassUser>;
  updateDefaultUserCenter?: Maybe<User>;
  updateHeartBeat?: Maybe<ClassUser>;
  updateInactivity?: Maybe<ClassUser>;
  updateRecording?: Maybe<Recording>;
  updateRecordingStatus?: Maybe<Scalars['String']>;
  updateToken?: Maybe<TokenResult>;
  updateUser?: Maybe<User>;
  updateUserDevices?: Maybe<UserDevices>;
  uploadBackgroundImage?: Maybe<BackgroundImage>;
  uploadDefaultBackgroundImage?: Maybe<Scalars['String']>;
  uploadWhiteBoardPDF?: Maybe<WhiteBoardPdf>;
  upsertClassUser?: Maybe<ClassUser>;
  upsertGroup?: Maybe<Group>;
  verifyTempToken?: Maybe<LoginResult>;
};


export type MutationAddUserCenterArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationBulkCreateCentersArgs = {
  centers?: InputMaybe<Array<InputMaybe<BulkCenterInput>>>;
};


export type MutationBulkCreateUsersArgs = {
  users?: InputMaybe<Array<InputMaybe<BulkUserInput>>>;
};


export type MutationCloseGroupArgs = {
  input?: InputMaybe<GroupInput>;
};


export type MutationCreateCenterArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateClassArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateClassUserSessionArgs = {
  input?: InputMaybe<ClassUserSessionInput>;
};


export type MutationCreateOrUpdateWhiteBoardArgs = {
  input?: InputMaybe<WhiteBoardInput>;
};


export type MutationCreateRecordingArgs = {
  input?: InputMaybe<RecordingInput>;
};


export type MutationCreateUserArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};


export type MutationCreateWhiteBoardServerArgs = {
  input?: InputMaybe<WhiteBoardServerInput>;
};


export type MutationDeleteBackgroundImageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCenterArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteClassArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWhiteBoardArgs = {
  whiteBoardId?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteWhiteBoardPdfArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWhiteBoardServerArgs = {
  address?: InputMaybe<Scalars['String']>;
  instanceId?: InputMaybe<Scalars['String']>;
};


export type MutationEndClassArgs = {
  slug: Scalars['String'];
};


export type MutationGenerateTempTokenArgs = {
  id: Scalars['ID'];
};


export type MutationJoinGroupArgs = {
  input?: InputMaybe<GroupUserInput>;
};


export type MutationLeaveGroupArgs = {
  input?: InputMaybe<GroupUserInput>;
};


export type MutationMakeActiveUserCenterArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationRemoveUserCenterArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationRemoveUserConnectionsArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  id: Scalars['ID'];
};


export type MutationResetToCustomPasswordArgs = {
  id: Scalars['ID'];
  password?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetDefaultImageArgs = {
  id: Scalars['ID'];
};


export type MutationStartClassArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCenterArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateClassUserArgs = {
  input?: InputMaybe<ClassUserUpdateInput>;
};


export type MutationUpdateClassUserConnectionArgs = {
  input?: InputMaybe<ClassUserUpdateConnectionInput>;
};


export type MutationUpdateDefaultUserCenterArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationUpdateHeartBeatArgs = {
  input?: InputMaybe<ClassUserInput>;
};


export type MutationUpdateInactivityArgs = {
  input?: InputMaybe<ClassUserInput>;
};


export type MutationUpdateRecordingArgs = {
  input?: InputMaybe<RecordingInputUpdate>;
};


export type MutationUpdateRecordingStatusArgs = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  duration?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  resolution?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateUserDevicesArgs = {
  input?: InputMaybe<UserDeviceInput>;
};


export type MutationUploadBackgroundImageArgs = {
  input?: InputMaybe<BackgroundImageInput>;
};


export type MutationUploadDefaultBackgroundImageArgs = {
  input?: InputMaybe<BackgroundImageInput>;
};


export type MutationUploadWhiteBoardPdfArgs = {
  input?: InputMaybe<WhiteBoardPdfUploadInput>;
};


export type MutationUpsertClassUserArgs = {
  input?: InputMaybe<ClassUserInput>;
};


export type MutationUpsertGroupArgs = {
  input?: InputMaybe<GroupInput>;
};


export type MutationVerifyTempTokenArgs = {
  temp_id: Scalars['String'];
};

export type OtaRchiveInput = {
  createdAt?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  resolution?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  edges?: Maybe<Array<Maybe<UserNode>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedWhiteBoardPdf = {
  __typename?: 'PaginatedWhiteBoardPDF';
  edges?: Maybe<Array<Maybe<WhiteBoardNode>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginationOptions = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
};

export type ProcessedPdf = {
  __typename?: 'ProcessedPDF';
  ProcessedPDFDetails?: Maybe<Array<Maybe<ProcessedPdfDetail>>>;
  fileName?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  whiteboardName?: Maybe<Scalars['String']>;
};

export type ProcessedPdfDetail = {
  __typename?: 'ProcessedPDFDetail';
  pageNumber?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  addStream?: Maybe<AddStreamResult>;
  filterUsers?: Maybe<Array<Maybe<User>>>;
  filterWhiteBoards?: Maybe<Array<Maybe<WhiteBoardWithUserAndServer>>>;
  getAllCenters?: Maybe<Array<Maybe<CenterWithRegion>>>;
  getAllClasses?: Maybe<Array<Maybe<Class>>>;
  getAllRegions?: Maybe<Array<Maybe<Region>>>;
  getAppSettings?: Maybe<AppSettings>;
  getBackgroundImageURL?: Maybe<Scalars['String']>;
  getBackgroundImages?: Maybe<Array<Maybe<BackgroundImage>>>;
  getCenterByCodeWithClasses?: Maybe<CenterWithClasses>;
  getCenterById?: Maybe<Center>;
  getCentersUsers?: Maybe<CenterWithUsers>;
  getClassById?: Maybe<Class>;
  getClassBySlug?: Maybe<ClassWhithRegion>;
  getClassUsersBySlug?: Maybe<Array<Maybe<ClassUserWithProfile>>>;
  getCurrentApiKey?: Maybe<Key>;
  getDefaultBackgroundImage?: Maybe<BackgroundImage>;
  getGroupsBySlug?: Maybe<Array<Maybe<Group>>>;
  getGroupsForUser?: Maybe<Array<Maybe<Group>>>;
  getNewApiKey?: Maybe<Key>;
  getPaginatedWhiteBoardPdfs?: Maybe<PaginatedWhiteBoardPdf>;
  getRecordingFileUrl?: Maybe<RecordingUrl>;
  getRecordings?: Maybe<Array<Maybe<RecordingSummary>>>;
  getSessionsBySlug?: Maybe<Array<Maybe<ClassUserSession>>>;
  getUserCenters?: Maybe<Array<Maybe<UserCenter>>>;
  getUserDetail?: Maybe<UserWithCenters>;
  getUserDevices?: Maybe<UserDevices>;
  getUserLimitedDetail?: Maybe<LimitedUser>;
  getUserPassword?: Maybe<Scalars['String']>;
  getUsersDetails?: Maybe<Array<Maybe<UserWithWhiteBoardCount>>>;
  getUsersDetailsDownload?: Maybe<Scalars['String']>;
  getUsersDetailsPaginated?: Maybe<PaginatedUsers>;
  getViewer?: Maybe<Viewer>;
  getViewerFromToken?: Maybe<User>;
  getWhiteBoard?: Maybe<WhiteBoardWithUserAndServer>;
  getWhiteBoardServers?: Maybe<Array<Maybe<WhiteBoardServer>>>;
  getWhiteBoards?: Maybe<Array<Maybe<WhiteBoardWithUserAndServer>>>;
  getWhiteboardPDFURL?: Maybe<Scalars['String']>;
  getWhiteboardPDFs?: Maybe<Array<Maybe<WhiteBoardPdf>>>;
  login?: Maybe<LoginResult>;
  startArchive?: Maybe<StartArchiveResult>;
  stopArchive?: Maybe<StopArchiveResult>;
};


export type QueryAddStreamArgs = {
  archiveId?: InputMaybe<Scalars['String']>;
  streamId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
};


export type QueryFilterUsersArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  textQuery?: InputMaybe<Scalars['String']>;
};


export type QueryFilterWhiteBoardsArgs = {
  filter?: InputMaybe<WhiteBoardInputFilter>;
};


export type QueryGetAllCentersArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetBackgroundImageUrlArgs = {
  id: Scalars['ID'];
};


export type QueryGetBackgroundImagesArgs = {
  filter?: InputMaybe<BackgroundImagesFilter>;
};


export type QueryGetCenterByCodeWithClassesArgs = {
  centerCode: Scalars['String'];
};


export type QueryGetCenterByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCentersUsersArgs = {
  id: Scalars['ID'];
};


export type QueryGetClassByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetClassBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetClassUsersBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetDefaultBackgroundImageArgs = {
  filter?: InputMaybe<BackgroundImagesFilter>;
};


export type QueryGetGroupsBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetGroupsForUserArgs = {
  slug: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPaginatedWhiteBoardPdfsArgs = {
  filter?: InputMaybe<WhiteBoardPdfFilterOptions>;
  pageOptions?: InputMaybe<PaginationOptions>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryGetRecordingFileUrlArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetRecordingsArgs = {
  filter?: InputMaybe<RecordingsFilter>;
};


export type QueryGetSessionsBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetUserCentersArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserDetailArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserDevicesArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserLimitedDetailArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserPasswordArgs = {
  id: Scalars['ID'];
};


export type QueryGetUsersDetailsArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  textQuery?: InputMaybe<Scalars['String']>;
};


export type QueryGetUsersDetailsDownloadArgs = {
  centerCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};


export type QueryGetUsersDetailsPaginatedArgs = {
  filter?: InputMaybe<UserFilterOptions>;
  pageOptions?: InputMaybe<PaginationOptions>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryGetViewerFromTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetWhiteBoardArgs = {
  whiteBoardId?: InputMaybe<Scalars['String']>;
};


export type QueryGetWhiteboardPdfurlArgs = {
  id: Scalars['ID'];
};


export type QueryGetWhiteboardPdFsArgs = {
  filter?: InputMaybe<WhiteBoardPdfFilterInput>;
};


export type QueryLoginArgs = {
  classSlug?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type QueryStartArchiveArgs = {
  archiveName?: InputMaybe<Scalars['String']>;
  centerCode?: InputMaybe<Scalars['String']>;
  instructorId?: InputMaybe<Scalars['Int']>;
  instructorShared?: InputMaybe<Scalars['Boolean']>;
  isBackgroundOff?: InputMaybe<Scalars['Boolean']>;
  sessionId?: InputMaybe<Scalars['String']>;
  streamId?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['Int']>;
  studentShared?: InputMaybe<Scalars['Boolean']>;
};


export type QueryStopArchiveArgs = {
  archiveId?: InputMaybe<Scalars['String']>;
  streamId?: InputMaybe<Scalars['String']>;
};

export type Recording = {
  __typename?: 'Recording';
  archiveId?: Maybe<Scalars['String']>;
  archiveName?: Maybe<Scalars['String']>;
  centerCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorId?: Maybe<Scalars['Int']>;
  instructorShared?: Maybe<Scalars['Boolean']>;
  isBackgroundOff?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['Int']>;
  studentShared?: Maybe<Scalars['Boolean']>;
};

export type RecordingInput = {
  archiveId?: InputMaybe<Scalars['String']>;
  archiveName?: InputMaybe<Scalars['String']>;
  centerCode?: InputMaybe<Scalars['String']>;
  instructorId?: InputMaybe<Scalars['Int']>;
  instructorShared?: InputMaybe<Scalars['Boolean']>;
  isBackgroundOff?: InputMaybe<Scalars['Boolean']>;
  studentId?: InputMaybe<Scalars['Int']>;
  studentShared?: InputMaybe<Scalars['Boolean']>;
};

export type RecordingInputUpdate = {
  archiveId?: InputMaybe<Scalars['String']>;
  archiveName?: InputMaybe<Scalars['String']>;
  centerCode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorId?: InputMaybe<Scalars['Int']>;
  instructorShared?: InputMaybe<Scalars['Boolean']>;
  isBackgroundOff?: InputMaybe<Scalars['Boolean']>;
  studentId?: InputMaybe<Scalars['Int']>;
  studentShared?: InputMaybe<Scalars['Boolean']>;
};

export type RecordingInputUpdateByStudent = {
  archiveId?: InputMaybe<Scalars['String']>;
  archiveName?: InputMaybe<Scalars['String']>;
  centerCode?: InputMaybe<Scalars['String']>;
  instructorId?: InputMaybe<Scalars['Int']>;
  instructorShared?: InputMaybe<Scalars['Boolean']>;
  isBackgroundOff?: InputMaybe<Scalars['Boolean']>;
  studentId: Scalars['Int'];
  studentShared?: InputMaybe<Scalars['Boolean']>;
};

export type RecordingSummary = {
  __typename?: 'RecordingSummary';
  archiveName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  instructorShared?: Maybe<Scalars['Boolean']>;
  isBackgroundOff?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  studentShared?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type RecordingUrl = {
  __typename?: 'RecordingUrl';
  url?: Maybe<Scalars['String']>;
};

export type RecordingsFilter = {
  centerCode?: InputMaybe<Scalars['String']>;
  day?: InputMaybe<Scalars['Int']>;
  instructorId?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['ID'];
  region?: Maybe<Scalars['String']>;
};

export type ServerResult = {
  __typename?: 'ServerResult';
  address?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
};

export type StartArchiveResult = {
  __typename?: 'StartArchiveResult';
  archiveId?: Maybe<Scalars['String']>;
  recordingId?: Maybe<Scalars['Int']>;
  studentId?: Maybe<Scalars['Int']>;
};

export type StopArchiveResult = {
  __typename?: 'StopArchiveResult';
  archiveId?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['Int']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  getClasses?: Maybe<Array<Maybe<Class>>>;
  getGroupsBySlug?: Maybe<Array<Maybe<Group>>>;
  getInstructorsInClass?: Maybe<Array<Maybe<ClassUserWithProfile>>>;
  getMyClassUserStatus?: Maybe<ClassUserWithProfile>;
  getStudentsInClass?: Maybe<Array<Maybe<ClassUserWithProfile>>>;
  getUsersInLobby?: Maybe<Array<Maybe<ClassUserWithProfile>>>;
};


export type SubscriptionGetGroupsBySlugArgs = {
  classSlug: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type SubscriptionGetInstructorsInClassArgs = {
  slug: Scalars['String'];
};


export type SubscriptionGetMyClassUserStatusArgs = {
  userId: Scalars['String'];
};


export type SubscriptionGetStudentsInClassArgs = {
  slug: Scalars['String'];
};


export type SubscriptionGetUsersInLobbyArgs = {
  slug: Scalars['String'];
};

export type TestToken = {
  __typename?: 'TestToken';
  apiKey?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type TokenResult = {
  __typename?: 'TokenResult';
  token?: Maybe<Scalars['String']>;
  tokenExp?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  centerCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserCenter = {
  __typename?: 'UserCenter';
  centerCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type UserDeviceInput = {
  devices?: InputMaybe<Array<InputMaybe<DeviceInfoInput>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserDevices = {
  __typename?: 'UserDevices';
  devices?: Maybe<Array<Maybe<DeviceInfo>>>;
  userId?: Maybe<Scalars['String']>;
};

export type UserFilterOptions = {
  centerCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type UserNode = {
  __typename?: 'UserNode';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<User>;
};

export type UserWithCenters = {
  __typename?: 'UserWithCenters';
  centerCode?: Maybe<Scalars['String']>;
  centers?: Maybe<Array<Maybe<Center>>>;
  defaultCenterCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserWithWhiteBoardCount = {
  __typename?: 'UserWithWhiteBoardCount';
  activeWhiteBoard?: Maybe<Scalars['String']>;
  centerCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  whiteBoardCount?: Maybe<WbCount>;
};

export type Viewer = {
  __typename?: 'Viewer';
  centerCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type WbCount = {
  __typename?: 'WbCount';
  active?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type WhiteBoard = {
  __typename?: 'WhiteBoard';
  createdAt?: Maybe<Scalars['DateTime']>;
  hostname?: Maybe<Scalars['String']>;
  isCreated?: Maybe<Scalars['Boolean']>;
  pdfUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  whiteBoardId?: Maybe<Scalars['String']>;
  whiteBoardName?: Maybe<Scalars['String']>;
};

export type WhiteBoardDeleteResult = {
  __typename?: 'WhiteBoardDeleteResult';
  message?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

export type WhiteBoardInput = {
  pdfUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  whiteBoardId?: InputMaybe<Scalars['String']>;
  whiteBoardName?: InputMaybe<Scalars['String']>;
};

export type WhiteBoardInputFilter = {
  centerCode?: InputMaybe<Scalars['String']>;
  classSlug?: InputMaybe<Scalars['String']>;
  connectorId?: InputMaybe<Scalars['String']>;
  pdfUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  whiteBoardId?: InputMaybe<Scalars['String']>;
  whiteBoardName?: InputMaybe<Scalars['String']>;
};

export type WhiteBoardNode = {
  __typename?: 'WhiteBoardNode';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<WhiteBoardPdf>;
};

export type WhiteBoardPdf = {
  __typename?: 'WhiteBoardPDF';
  User?: Maybe<User>;
  centerCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type WhiteBoardPdfFilterInput = {
  centerCode?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WhiteBoardPdfFilterOptions = {
  centerCode?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WhiteBoardPdfInput = {
  centerCode?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WhiteBoardPdfUploadInput = {
  centerCode?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WhiteBoardServer = {
  __typename?: 'WhiteBoardServer';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  instanceId?: Maybe<Scalars['String']>;
  privateIp?: Maybe<Scalars['String']>;
  publicIp?: Maybe<Scalars['String']>;
};

export type WhiteBoardServerDeleteResult = {
  __typename?: 'WhiteBoardServerDeleteResult';
  message?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  server?: Maybe<ServerResult>;
};

export type WhiteBoardServerInput = {
  hostname?: InputMaybe<Scalars['String']>;
  instanceId?: InputMaybe<Scalars['String']>;
  privateIp?: InputMaybe<Scalars['String']>;
  publicIp?: InputMaybe<Scalars['String']>;
};

export type WhiteBoardWithUserAndServer = {
  __typename?: 'WhiteBoardWithUserAndServer';
  ProcessedPDF?: Maybe<ProcessedPdf>;
  User?: Maybe<User>;
  WhiteBoardServer?: Maybe<WhiteBoardServer>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isCreated?: Maybe<Scalars['Boolean']>;
  pdfUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  whiteBoardId?: Maybe<Scalars['String']>;
  whiteBoardName?: Maybe<Scalars['String']>;
};

export type GetAppSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppSettingsQuery = { __typename?: 'Query', getAppSettings?: { __typename?: 'AppSettings', defaultRemoteAccess?: boolean | null, defaultLogoutTimeout?: number | null, studentLogoutTimeout?: number | null, instructorLogoutTimeout?: number | null } | null };

export type GetBackgroundImagesQueryVariables = Exact<{
  filter?: InputMaybe<BackgroundImagesFilter>;
}>;


export type GetBackgroundImagesQuery = { __typename?: 'Query', getBackgroundImages?: Array<{ __typename?: 'BackgroundImage', id?: string | null, imgName?: string | null, imgUrl?: string | null, centerCode?: string | null, createdAt?: any | null, key?: string | null } | null> | null };

export type GetDefaultBackgroundImageQueryVariables = Exact<{
  filter?: InputMaybe<BackgroundImagesFilter>;
}>;


export type GetDefaultBackgroundImageQuery = { __typename?: 'Query', getDefaultBackgroundImage?: { __typename?: 'BackgroundImage', key?: string | null, imgName?: string | null, imgUrl?: string | null } | null };

export type GetAllClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllClassesQuery = { __typename?: 'Query', getAllClasses?: Array<{ __typename?: 'Class', id: string, slug?: string | null, name?: string | null, vonage_session?: string | null, status?: string | null } | null> | null };

export type GetClassByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClassByIdQuery = { __typename?: 'Query', getClassById?: { __typename?: 'Class', id: string, slug?: string | null, name?: string | null, vonage_session?: string | null, status?: string | null, language?: string | null } | null };

export type GetClassBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetClassBySlugQuery = { __typename?: 'Query', getClassBySlug?: { __typename?: 'ClassWhithRegion', id: string, slug?: string | null, name?: string | null, vonage_session?: string | null, status?: string | null, language?: string | null, centerCode?: string | null, region?: string | null, regionClassUrl?: string | null } | null };

export type GetClassBySlugWithUserQueryVariables = Exact<{
  slug: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetClassBySlugWithUserQuery = { __typename?: 'Query', getClassBySlug?: { __typename?: 'ClassWhithRegion', id: string, slug?: string | null, name?: string | null, vonage_session?: string | null, status?: string | null, language?: string | null, region?: string | null, regionClassUrl?: string | null } | null, getViewerFromToken?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string, role?: string | null, username?: string | null, centerCode?: string | null, verified?: boolean | null } | null };

export type StartClassMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StartClassMutation = { __typename?: 'Mutation', startClass?: { __typename?: 'Class', id: string, slug?: string | null, name?: string | null, vonage_session?: string | null, status?: string | null } | null };

export type GenerateTestTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateTestTokenMutation = { __typename?: 'Mutation', generateTestToken?: { __typename?: 'TestToken', apiKey?: string | null, sessionId?: string | null, token?: string | null } | null };

export type ClassUserFragment = { __typename?: 'ClassUser', classSlug?: string | null, id: string, location?: string | null, role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, handRaised?: boolean | null, connectionStatus?: number | null, inactive?: boolean | null, os?: string | null, userAgent?: string | null, permissions?: string | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } | null> | null };

export type GetClassUsersBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetClassUsersBySlugQuery = { __typename?: 'Query', getClassUsersBySlug?: Array<{ __typename?: 'ClassUserWithProfile', classSlug?: string | null, id: string, location?: string | null, role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, handRaised?: boolean | null, connectionStatus?: number | null, inactive?: boolean | null, os?: string | null, userAgent?: string | null, permissions?: string | null, User?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string, role?: string | null, username?: string | null, profilePictureUrl?: string | null } | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } | null> | null } | null> | null };

export type UpsertClassUserMutationVariables = Exact<{
  input?: InputMaybe<ClassUserInput>;
}>;


export type UpsertClassUserMutation = { __typename?: 'Mutation', upsertClassUser?: { __typename?: 'ClassUser', classSlug?: string | null, id: string, location?: string | null, role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, handRaised?: boolean | null, connectionStatus?: number | null, inactive?: boolean | null, os?: string | null, userAgent?: string | null, permissions?: string | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } | null> | null } | null };

export type UpdateHeartBeatMutationVariables = Exact<{
  input?: InputMaybe<ClassUserInput>;
}>;


export type UpdateHeartBeatMutation = { __typename?: 'Mutation', updateHeartBeat?: { __typename?: 'ClassUser', classSlug?: string | null, id: string, location?: string | null, role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, handRaised?: boolean | null, connectionStatus?: number | null, inactive?: boolean | null, os?: string | null, userAgent?: string | null, permissions?: string | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } | null> | null } | null };

export type UpdateInactiveStatusMutationVariables = Exact<{
  input?: InputMaybe<ClassUserInput>;
}>;


export type UpdateInactiveStatusMutation = { __typename?: 'Mutation', updateInactivity?: { __typename?: 'ClassUser', classSlug?: string | null, id: string, location?: string | null, role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, handRaised?: boolean | null, connectionStatus?: number | null, inactive?: boolean | null, os?: string | null, userAgent?: string | null, permissions?: string | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } | null> | null } | null };

export type UpdateConnectedToMutationVariables = Exact<{
  input?: InputMaybe<ClassUserUpdateConnectionInput>;
}>;


export type UpdateConnectedToMutation = { __typename?: 'Mutation', updateClassUserConnection?: { __typename?: 'ClassUser', classSlug?: string | null, id: string, location?: string | null, role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, handRaised?: boolean | null, connectionStatus?: number | null, inactive?: boolean | null, os?: string | null, userAgent?: string | null, permissions?: string | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } | null> | null } | null };

export type CreateClassUserSessionMutationVariables = Exact<{
  input?: InputMaybe<ClassUserSessionInput>;
}>;


export type CreateClassUserSessionMutation = { __typename?: 'Mutation', createClassUserSession?: { __typename?: 'ClassUserSession', classSlug?: string | null, userId?: string | null, sessionId?: string | null, connectionId?: string | null, connectedAt?: any | null, disconnectedAt?: any | null } | null };

export type GroupFragment = { __typename?: 'Group', id?: number | null, ownerId?: number | null, status?: string | null, whiteBoardId?: number | null, classSlug?: string | null, groupName?: string | null };

export type GetGroupsBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetGroupsBySlugQuery = { __typename?: 'Query', getGroupsBySlug?: Array<{ __typename?: 'Group', id?: number | null, ownerId?: number | null, status?: string | null, whiteBoardId?: number | null, classSlug?: string | null, groupName?: string | null } | null> | null };

export type UpsertGroupMutationVariables = Exact<{
  input?: InputMaybe<GroupInput>;
}>;


export type UpsertGroupMutation = { __typename?: 'Mutation', upsertGroup?: { __typename?: 'Group', id?: number | null, ownerId?: number | null, status?: string | null, whiteBoardId?: number | null, classSlug?: string | null, groupName?: string | null } | null };

export type CloseGroupMutationVariables = Exact<{
  input?: InputMaybe<GroupInput>;
}>;


export type CloseGroupMutation = { __typename?: 'Mutation', closeGroup?: { __typename?: 'Group', id?: number | null, ownerId?: number | null, status?: string | null, whiteBoardId?: number | null, classSlug?: string | null, groupName?: string | null } | null };

export type JoinGroupMutationVariables = Exact<{
  input?: InputMaybe<GroupUserInput>;
}>;


export type JoinGroupMutation = { __typename?: 'Mutation', joinGroup?: { __typename?: 'Group', id?: number | null, ownerId?: number | null, status?: string | null, whiteBoardId?: number | null, classSlug?: string | null, groupName?: string | null } | null };

export type LeaveGroupMutationVariables = Exact<{
  input?: InputMaybe<GroupUserInput>;
}>;


export type LeaveGroupMutation = { __typename?: 'Mutation', leaveGroup?: { __typename?: 'Group', id?: number | null, ownerId?: number | null, status?: string | null, whiteBoardId?: number | null, classSlug?: string | null, groupName?: string | null } | null };

export type RecordingFragment = { __typename?: 'Recording', id: string, archiveId?: string | null, archiveName?: string | null, instructorId?: number | null, studentId?: number | null, centerCode?: string | null, isBackgroundOff?: boolean | null, instructorShared?: boolean | null, studentShared?: boolean | null };

export type UpdateRecordingMutationVariables = Exact<{
  input?: InputMaybe<RecordingInputUpdate>;
}>;


export type UpdateRecordingMutation = { __typename?: 'Mutation', updateRecording?: { __typename?: 'Recording', id: string, archiveId?: string | null, archiveName?: string | null, instructorId?: number | null, studentId?: number | null, centerCode?: string | null, isBackgroundOff?: boolean | null, instructorShared?: boolean | null, studentShared?: boolean | null } | null };

export type GetMyClassUserStatusSubscriptionVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetMyClassUserStatusSubscription = { __typename?: 'Subscription', getMyClassUserStatus?: { __typename?: 'ClassUserWithProfile', role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, handRaised?: boolean | null, connectionStatus?: number | null, inactive?: boolean | null, userMode?: string | null, isMobile?: boolean | null, location?: string | null, permissions?: string | null, User?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string } | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string, role?: string | null } | null> | null } | null };

export type GetUsersInLobbySubscriptionVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetUsersInLobbySubscription = { __typename?: 'Subscription', getUsersInLobby?: Array<{ __typename?: 'ClassUserWithProfile', role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, userMode?: string | null, isMobile?: boolean | null, permissions?: string | null, userAgent?: string | null, os?: string | null, User?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string, role?: string | null, username?: string | null } | null } | null> | null };

export type GetStudentsInClassSubscriptionVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetStudentsInClassSubscription = { __typename?: 'Subscription', getStudentsInClass?: Array<{ __typename?: 'ClassUserWithProfile', role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, inactive?: boolean | null, permissions?: string | null, userAgent?: string | null, os?: string | null, handRaised?: boolean | null, userMode?: string | null, isMobile?: boolean | null, connectionStatus?: number | null, User?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string, username?: string | null } | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string, role?: string | null } | null> | null } | null> | null };

export type GetInstructorsInClassSubscriptionVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetInstructorsInClassSubscription = { __typename?: 'Subscription', getInstructorsInClass?: Array<{ __typename?: 'ClassUserWithProfile', role?: string | null, schedule?: string | null, status?: string | null, userId?: string | null, inactive?: boolean | null, userMode?: string | null, isMobile?: boolean | null, User?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string, username?: string | null } | null, Connections?: Array<{ __typename?: 'User', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string, role?: string | null } | null> | null } | null> | null };

export type GetAllGroupsBySlugSubscriptionVariables = Exact<{
  classSlug: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['String']>;
}>;


export type GetAllGroupsBySlugSubscription = { __typename?: 'Subscription', getGroupsBySlug?: Array<{ __typename?: 'Group', id?: number | null, ownerId?: number | null, status?: string | null, whiteBoardId?: number | null, classSlug?: string | null, groupName?: string | null, members?: Array<{ __typename?: 'GroupUser', firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, id: string, GroupUserStatus?: { __typename?: 'GroupUserStatus', status?: string | null } | null } | null> | null } | null> | null };

export type GetViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetViewerQuery = { __typename?: 'Query', getViewer?: { __typename?: 'Viewer', firstName?: string | null, lastName?: string | null, id: string, role?: string | null, username?: string | null, centerCode?: string | null, verified?: boolean | null, profilePictureUrl?: string | null } | null };

export type GetUserDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserDetailsQuery = { __typename?: 'Query', getUsersDetails?: Array<{ __typename?: 'UserWithWhiteBoardCount', firstName?: string | null, lastName?: string | null, id: string, role?: string | null, username?: string | null, profilePictureUrl?: string | null } | null> | null };

export type GetUserDetailsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDetailsByIdQuery = { __typename?: 'Query', getUserDetail?: { __typename?: 'UserWithCenters', firstName?: string | null, lastName?: string | null, id: string, role?: string | null, username?: string | null, profilePictureUrl?: string | null, centerCode?: string | null } | null };

export type GetUserLimitedDetailsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserLimitedDetailsByIdQuery = { __typename?: 'Query', getUserLimitedDetail?: { __typename?: 'LimitedUser', id: string, role?: string | null, centerCode?: string | null } | null };

export type GetViewerFromTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetViewerFromTokenQuery = { __typename?: 'Query', getViewerFromToken?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string, role?: string | null, username?: string | null, centerCode?: string | null, verified?: boolean | null, profilePictureUrl?: string | null } | null };

export type LoginUserQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  classSlug?: InputMaybe<Scalars['String']>;
}>;


export type LoginUserQuery = { __typename?: 'Query', login?: { __typename?: 'LoginResult', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, centerCode?: string | null, uuid?: string | null, verified?: boolean | null, role?: string | null, token?: string | null, error?: string | null, profilePictureUrl?: string | null, timezone?: string | null, backendContainerIp?: string | null, tokenExp?: string | null, availableClasses?: Array<{ __typename?: 'ClassWhithRegion', name?: string | null, slug?: string | null, centerCode?: string | null } | null> | null } | null };

export type ResetToCustomPasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  password: Scalars['String'];
  verified: Scalars['Boolean'];
}>;


export type ResetToCustomPasswordMutation = { __typename?: 'Mutation', resetToCustomPassword?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, role?: string | null, centerCode?: string | null, username?: string | null, uuid?: string | null, id: string, verified?: boolean | null } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: string | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, role?: string | null, username?: string | null, id: string, verified?: boolean | null, profilePictureUrl?: string | null } | null };

export type StartArchiveQueryVariables = Exact<{
  archiveName: Scalars['String'];
  sessionId: Scalars['String'];
  streamId: Scalars['String'];
  instructorId: Scalars['Int'];
  studentId: Scalars['Int'];
  centerCode: Scalars['String'];
  isBackgroundOff: Scalars['Boolean'];
  instructorShared: Scalars['Boolean'];
  studentShared: Scalars['Boolean'];
}>;


export type StartArchiveQuery = { __typename?: 'Query', startArchive?: { __typename?: 'StartArchiveResult', archiveId?: string | null, studentId?: number | null, recordingId?: number | null } | null };

export type StopArchiveQueryVariables = Exact<{
  archiveId: Scalars['String'];
  streamId: Scalars['String'];
}>;


export type StopArchiveQuery = { __typename?: 'Query', stopArchive?: { __typename?: 'StopArchiveResult', archiveId?: string | null, studentId?: number | null } | null };

export type AddStreamQueryVariables = Exact<{
  archiveId: Scalars['String'];
  streamId: Scalars['String'];
  type: Scalars['Int'];
}>;


export type AddStreamQuery = { __typename?: 'Query', addStream?: { __typename?: 'AddStreamResult', archiveId?: string | null } | null };

export type MakeActiveUserCenterMutationVariables = Exact<{
  id: Scalars['String'];
  centerCode?: InputMaybe<Scalars['String']>;
}>;


export type MakeActiveUserCenterMutation = { __typename?: 'Mutation', makeActiveUserCenter?: { __typename?: 'LoginResult', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, centerCode?: string | null, uuid?: string | null, verified?: boolean | null, role?: string | null, token?: string | null, error?: string | null, profilePictureUrl?: string | null, timezone?: string | null, availableClasses?: Array<{ __typename?: 'ClassWhithRegion', name?: string | null, slug?: string | null, centerCode?: string | null } | null> | null } | null };

export type GenerateTempTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GenerateTempTokenMutation = { __typename?: 'Mutation', generateTempToken?: string | null };

export type VerifyTempTokenMutationVariables = Exact<{
  temp_id: Scalars['String'];
}>;


export type VerifyTempTokenMutation = { __typename?: 'Mutation', verifyTempToken?: { __typename?: 'LoginResult', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, centerCode?: string | null, uuid?: string | null, verified?: boolean | null, role?: string | null, token?: string | null, error?: string | null, profilePictureUrl?: string | null, timezone?: string | null, backendContainerIp?: string | null, availableClasses?: Array<{ __typename?: 'ClassWhithRegion', name?: string | null, slug?: string | null, centerCode?: string | null } | null> | null } | null };

export type UpdateTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateTokenMutation = { __typename?: 'Mutation', updateToken?: { __typename?: 'TokenResult', token?: string | null, tokenExp?: string | null } | null };

export type GetUserDevicesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserDevicesQuery = { __typename?: 'Query', getUserDevices?: { __typename?: 'UserDevices', userId?: string | null, devices?: Array<{ __typename?: 'DeviceInfo', id?: string | null, name?: string | null, type?: string | null, selected?: boolean | null } | null> | null } | null };

export type UpdateUserDevicesMutationVariables = Exact<{
  input?: InputMaybe<UserDeviceInput>;
}>;


export type UpdateUserDevicesMutation = { __typename?: 'Mutation', updateUserDevices?: { __typename?: 'UserDevices', userId?: string | null, devices?: Array<{ __typename?: 'DeviceInfo', id?: string | null, name?: string | null, type?: string | null, selected?: boolean | null } | null> | null } | null };

export type GetActiveBoardQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  centerCode?: InputMaybe<Scalars['String']>;
  connectorId?: InputMaybe<Scalars['String']>;
  classSlug?: InputMaybe<Scalars['String']>;
}>;


export type GetActiveBoardQuery = { __typename?: 'Query', filterWhiteBoards?: Array<{ __typename?: 'WhiteBoardWithUserAndServer', status?: string | null, userId?: string | null, whiteBoardId?: string | null, pdfUrl?: string | null, whiteBoardName?: string | null, createdAt?: any | null, updatedAt?: any | null, isCreated?: boolean | null, User?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, WhiteBoardServer?: { __typename?: 'WhiteBoardServer', address?: string | null } | null, ProcessedPDF?: { __typename?: 'ProcessedPDF', fileName?: string | null, whiteboardName?: string | null, studentId?: string | null, ProcessedPDFDetails?: Array<{ __typename?: 'ProcessedPDFDetail', pageNumber?: number | null, url?: string | null } | null> | null } | null } | null> | null };

export const ClassUserFragmentDoc = gql`
    fragment classUser on ClassUser {
  classSlug
  id
  location
  role
  schedule
  status
  userId
  handRaised
  connectionStatus
  inactive
  os
  userAgent
  permissions
  Connections {
    firstName
    lastName
    id
  }
}
    `;
export const GroupFragmentDoc = gql`
    fragment group on Group {
  id
  ownerId
  status
  whiteBoardId
  classSlug
  groupName
}
    `;
export const RecordingFragmentDoc = gql`
    fragment recording on Recording {
  id
  archiveId
  archiveName
  instructorId
  studentId
  centerCode
  isBackgroundOff
  instructorShared
  studentShared
}
    `;
export const GetAppSettingsDocument = gql`
    query GetAppSettings {
  getAppSettings {
    defaultRemoteAccess
    defaultLogoutTimeout
    studentLogoutTimeout
    instructorLogoutTimeout
  }
}
    `;

/**
 * __useGetAppSettingsQuery__
 *
 * To run a query within a React component, call `useGetAppSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, options);
      }
export function useGetAppSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppSettingsQuery, GetAppSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppSettingsQuery, GetAppSettingsQueryVariables>(GetAppSettingsDocument, options);
        }
export type GetAppSettingsQueryHookResult = ReturnType<typeof useGetAppSettingsQuery>;
export type GetAppSettingsLazyQueryHookResult = ReturnType<typeof useGetAppSettingsLazyQuery>;
export type GetAppSettingsQueryResult = Apollo.QueryResult<GetAppSettingsQuery, GetAppSettingsQueryVariables>;
export const GetBackgroundImagesDocument = gql`
    query getBackgroundImages($filter: BackgroundImagesFilter) {
  getBackgroundImages(filter: $filter) {
    id
    imgName
    imgUrl
    centerCode
    createdAt
    key
  }
}
    `;

/**
 * __useGetBackgroundImagesQuery__
 *
 * To run a query within a React component, call `useGetBackgroundImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackgroundImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackgroundImagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetBackgroundImagesQuery(baseOptions?: Apollo.QueryHookOptions<GetBackgroundImagesQuery, GetBackgroundImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBackgroundImagesQuery, GetBackgroundImagesQueryVariables>(GetBackgroundImagesDocument, options);
      }
export function useGetBackgroundImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBackgroundImagesQuery, GetBackgroundImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBackgroundImagesQuery, GetBackgroundImagesQueryVariables>(GetBackgroundImagesDocument, options);
        }
export type GetBackgroundImagesQueryHookResult = ReturnType<typeof useGetBackgroundImagesQuery>;
export type GetBackgroundImagesLazyQueryHookResult = ReturnType<typeof useGetBackgroundImagesLazyQuery>;
export type GetBackgroundImagesQueryResult = Apollo.QueryResult<GetBackgroundImagesQuery, GetBackgroundImagesQueryVariables>;
export const GetDefaultBackgroundImageDocument = gql`
    query getDefaultBackgroundImage($filter: BackgroundImagesFilter) {
  getDefaultBackgroundImage(filter: $filter) {
    key
    imgName
    imgUrl
  }
}
    `;

/**
 * __useGetDefaultBackgroundImageQuery__
 *
 * To run a query within a React component, call `useGetDefaultBackgroundImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultBackgroundImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultBackgroundImageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDefaultBackgroundImageQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultBackgroundImageQuery, GetDefaultBackgroundImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultBackgroundImageQuery, GetDefaultBackgroundImageQueryVariables>(GetDefaultBackgroundImageDocument, options);
      }
export function useGetDefaultBackgroundImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultBackgroundImageQuery, GetDefaultBackgroundImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultBackgroundImageQuery, GetDefaultBackgroundImageQueryVariables>(GetDefaultBackgroundImageDocument, options);
        }
export type GetDefaultBackgroundImageQueryHookResult = ReturnType<typeof useGetDefaultBackgroundImageQuery>;
export type GetDefaultBackgroundImageLazyQueryHookResult = ReturnType<typeof useGetDefaultBackgroundImageLazyQuery>;
export type GetDefaultBackgroundImageQueryResult = Apollo.QueryResult<GetDefaultBackgroundImageQuery, GetDefaultBackgroundImageQueryVariables>;
export const GetAllClassesDocument = gql`
    query GetAllClasses {
  getAllClasses {
    id
    slug
    name
    vonage_session
    status
  }
}
    `;

/**
 * __useGetAllClassesQuery__
 *
 * To run a query within a React component, call `useGetAllClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllClassesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllClassesQuery, GetAllClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllClassesQuery, GetAllClassesQueryVariables>(GetAllClassesDocument, options);
      }
export function useGetAllClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllClassesQuery, GetAllClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllClassesQuery, GetAllClassesQueryVariables>(GetAllClassesDocument, options);
        }
export type GetAllClassesQueryHookResult = ReturnType<typeof useGetAllClassesQuery>;
export type GetAllClassesLazyQueryHookResult = ReturnType<typeof useGetAllClassesLazyQuery>;
export type GetAllClassesQueryResult = Apollo.QueryResult<GetAllClassesQuery, GetAllClassesQueryVariables>;
export const GetClassByIdDocument = gql`
    query GetClassByID($id: ID!) {
  getClassById(id: $id) {
    id
    slug
    name
    vonage_session
    status
    language
  }
}
    `;

/**
 * __useGetClassByIdQuery__
 *
 * To run a query within a React component, call `useGetClassByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassByIdQuery(baseOptions: Apollo.QueryHookOptions<GetClassByIdQuery, GetClassByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassByIdQuery, GetClassByIdQueryVariables>(GetClassByIdDocument, options);
      }
export function useGetClassByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassByIdQuery, GetClassByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassByIdQuery, GetClassByIdQueryVariables>(GetClassByIdDocument, options);
        }
export type GetClassByIdQueryHookResult = ReturnType<typeof useGetClassByIdQuery>;
export type GetClassByIdLazyQueryHookResult = ReturnType<typeof useGetClassByIdLazyQuery>;
export type GetClassByIdQueryResult = Apollo.QueryResult<GetClassByIdQuery, GetClassByIdQueryVariables>;
export const GetClassBySlugDocument = gql`
    query GetClassBySlug($slug: String!) {
  getClassBySlug(slug: $slug) {
    id
    slug
    name
    vonage_session
    status
    language
    centerCode
    region
    regionClassUrl
  }
}
    `;

/**
 * __useGetClassBySlugQuery__
 *
 * To run a query within a React component, call `useGetClassBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetClassBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetClassBySlugQuery, GetClassBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassBySlugQuery, GetClassBySlugQueryVariables>(GetClassBySlugDocument, options);
      }
export function useGetClassBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassBySlugQuery, GetClassBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassBySlugQuery, GetClassBySlugQueryVariables>(GetClassBySlugDocument, options);
        }
export type GetClassBySlugQueryHookResult = ReturnType<typeof useGetClassBySlugQuery>;
export type GetClassBySlugLazyQueryHookResult = ReturnType<typeof useGetClassBySlugLazyQuery>;
export type GetClassBySlugQueryResult = Apollo.QueryResult<GetClassBySlugQuery, GetClassBySlugQueryVariables>;
export const GetClassBySlugWithUserDocument = gql`
    query GetClassBySlugWithUser($slug: String!, $token: String!) {
  getClassBySlug(slug: $slug) {
    id
    slug
    name
    vonage_session
    status
    language
    region
    regionClassUrl
  }
  getViewerFromToken(token: $token) {
    firstName
    lastName
    id
    role
    username
    centerCode
    verified
  }
}
    `;

/**
 * __useGetClassBySlugWithUserQuery__
 *
 * To run a query within a React component, call `useGetClassBySlugWithUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassBySlugWithUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassBySlugWithUserQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetClassBySlugWithUserQuery(baseOptions: Apollo.QueryHookOptions<GetClassBySlugWithUserQuery, GetClassBySlugWithUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassBySlugWithUserQuery, GetClassBySlugWithUserQueryVariables>(GetClassBySlugWithUserDocument, options);
      }
export function useGetClassBySlugWithUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassBySlugWithUserQuery, GetClassBySlugWithUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassBySlugWithUserQuery, GetClassBySlugWithUserQueryVariables>(GetClassBySlugWithUserDocument, options);
        }
export type GetClassBySlugWithUserQueryHookResult = ReturnType<typeof useGetClassBySlugWithUserQuery>;
export type GetClassBySlugWithUserLazyQueryHookResult = ReturnType<typeof useGetClassBySlugWithUserLazyQuery>;
export type GetClassBySlugWithUserQueryResult = Apollo.QueryResult<GetClassBySlugWithUserQuery, GetClassBySlugWithUserQueryVariables>;
export const StartClassDocument = gql`
    mutation StartClass($id: ID!) {
  startClass(id: $id) {
    id
    slug
    name
    vonage_session
    status
  }
}
    `;
export type StartClassMutationFn = Apollo.MutationFunction<StartClassMutation, StartClassMutationVariables>;

/**
 * __useStartClassMutation__
 *
 * To run a mutation, you first call `useStartClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startClassMutation, { data, loading, error }] = useStartClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartClassMutation(baseOptions?: Apollo.MutationHookOptions<StartClassMutation, StartClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartClassMutation, StartClassMutationVariables>(StartClassDocument, options);
      }
export type StartClassMutationHookResult = ReturnType<typeof useStartClassMutation>;
export type StartClassMutationResult = Apollo.MutationResult<StartClassMutation>;
export type StartClassMutationOptions = Apollo.BaseMutationOptions<StartClassMutation, StartClassMutationVariables>;
export const GenerateTestTokenDocument = gql`
    mutation generateTestToken {
  generateTestToken {
    apiKey
    sessionId
    token
  }
}
    `;
export type GenerateTestTokenMutationFn = Apollo.MutationFunction<GenerateTestTokenMutation, GenerateTestTokenMutationVariables>;

/**
 * __useGenerateTestTokenMutation__
 *
 * To run a mutation, you first call `useGenerateTestTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTestTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTestTokenMutation, { data, loading, error }] = useGenerateTestTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateTestTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTestTokenMutation, GenerateTestTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTestTokenMutation, GenerateTestTokenMutationVariables>(GenerateTestTokenDocument, options);
      }
export type GenerateTestTokenMutationHookResult = ReturnType<typeof useGenerateTestTokenMutation>;
export type GenerateTestTokenMutationResult = Apollo.MutationResult<GenerateTestTokenMutation>;
export type GenerateTestTokenMutationOptions = Apollo.BaseMutationOptions<GenerateTestTokenMutation, GenerateTestTokenMutationVariables>;
export const GetClassUsersBySlugDocument = gql`
    query GetClassUsersBySlug($slug: String!) {
  getClassUsersBySlug(slug: $slug) {
    classSlug
    id
    location
    role
    schedule
    status
    userId
    handRaised
    connectionStatus
    inactive
    os
    userAgent
    permissions
    User {
      firstName
      lastName
      id
      role
      username
      profilePictureUrl
    }
    Connections {
      firstName
      lastName
      id
    }
  }
}
    `;

/**
 * __useGetClassUsersBySlugQuery__
 *
 * To run a query within a React component, call `useGetClassUsersBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassUsersBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassUsersBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetClassUsersBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetClassUsersBySlugQuery, GetClassUsersBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassUsersBySlugQuery, GetClassUsersBySlugQueryVariables>(GetClassUsersBySlugDocument, options);
      }
export function useGetClassUsersBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassUsersBySlugQuery, GetClassUsersBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassUsersBySlugQuery, GetClassUsersBySlugQueryVariables>(GetClassUsersBySlugDocument, options);
        }
export type GetClassUsersBySlugQueryHookResult = ReturnType<typeof useGetClassUsersBySlugQuery>;
export type GetClassUsersBySlugLazyQueryHookResult = ReturnType<typeof useGetClassUsersBySlugLazyQuery>;
export type GetClassUsersBySlugQueryResult = Apollo.QueryResult<GetClassUsersBySlugQuery, GetClassUsersBySlugQueryVariables>;
export const UpsertClassUserDocument = gql`
    mutation UpsertClassUser($input: ClassUserInput) {
  upsertClassUser(input: $input) {
    ...classUser
  }
}
    ${ClassUserFragmentDoc}`;
export type UpsertClassUserMutationFn = Apollo.MutationFunction<UpsertClassUserMutation, UpsertClassUserMutationVariables>;

/**
 * __useUpsertClassUserMutation__
 *
 * To run a mutation, you first call `useUpsertClassUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertClassUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertClassUserMutation, { data, loading, error }] = useUpsertClassUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertClassUserMutation(baseOptions?: Apollo.MutationHookOptions<UpsertClassUserMutation, UpsertClassUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertClassUserMutation, UpsertClassUserMutationVariables>(UpsertClassUserDocument, options);
      }
export type UpsertClassUserMutationHookResult = ReturnType<typeof useUpsertClassUserMutation>;
export type UpsertClassUserMutationResult = Apollo.MutationResult<UpsertClassUserMutation>;
export type UpsertClassUserMutationOptions = Apollo.BaseMutationOptions<UpsertClassUserMutation, UpsertClassUserMutationVariables>;
export const UpdateHeartBeatDocument = gql`
    mutation UpdateHeartBeat($input: ClassUserInput) {
  updateHeartBeat(input: $input) {
    ...classUser
  }
}
    ${ClassUserFragmentDoc}`;
export type UpdateHeartBeatMutationFn = Apollo.MutationFunction<UpdateHeartBeatMutation, UpdateHeartBeatMutationVariables>;

/**
 * __useUpdateHeartBeatMutation__
 *
 * To run a mutation, you first call `useUpdateHeartBeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHeartBeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHeartBeatMutation, { data, loading, error }] = useUpdateHeartBeatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHeartBeatMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeartBeatMutation, UpdateHeartBeatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHeartBeatMutation, UpdateHeartBeatMutationVariables>(UpdateHeartBeatDocument, options);
      }
export type UpdateHeartBeatMutationHookResult = ReturnType<typeof useUpdateHeartBeatMutation>;
export type UpdateHeartBeatMutationResult = Apollo.MutationResult<UpdateHeartBeatMutation>;
export type UpdateHeartBeatMutationOptions = Apollo.BaseMutationOptions<UpdateHeartBeatMutation, UpdateHeartBeatMutationVariables>;
export const UpdateInactiveStatusDocument = gql`
    mutation UpdateInactiveStatus($input: ClassUserInput) {
  updateInactivity(input: $input) {
    ...classUser
  }
}
    ${ClassUserFragmentDoc}`;
export type UpdateInactiveStatusMutationFn = Apollo.MutationFunction<UpdateInactiveStatusMutation, UpdateInactiveStatusMutationVariables>;

/**
 * __useUpdateInactiveStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInactiveStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInactiveStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInactiveStatusMutation, { data, loading, error }] = useUpdateInactiveStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInactiveStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInactiveStatusMutation, UpdateInactiveStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInactiveStatusMutation, UpdateInactiveStatusMutationVariables>(UpdateInactiveStatusDocument, options);
      }
export type UpdateInactiveStatusMutationHookResult = ReturnType<typeof useUpdateInactiveStatusMutation>;
export type UpdateInactiveStatusMutationResult = Apollo.MutationResult<UpdateInactiveStatusMutation>;
export type UpdateInactiveStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInactiveStatusMutation, UpdateInactiveStatusMutationVariables>;
export const UpdateConnectedToDocument = gql`
    mutation updateConnectedTo($input: ClassUserUpdateConnectionInput) {
  updateClassUserConnection(input: $input) {
    ...classUser
  }
}
    ${ClassUserFragmentDoc}`;
export type UpdateConnectedToMutationFn = Apollo.MutationFunction<UpdateConnectedToMutation, UpdateConnectedToMutationVariables>;

/**
 * __useUpdateConnectedToMutation__
 *
 * To run a mutation, you first call `useUpdateConnectedToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectedToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectedToMutation, { data, loading, error }] = useUpdateConnectedToMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectedToMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConnectedToMutation, UpdateConnectedToMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConnectedToMutation, UpdateConnectedToMutationVariables>(UpdateConnectedToDocument, options);
      }
export type UpdateConnectedToMutationHookResult = ReturnType<typeof useUpdateConnectedToMutation>;
export type UpdateConnectedToMutationResult = Apollo.MutationResult<UpdateConnectedToMutation>;
export type UpdateConnectedToMutationOptions = Apollo.BaseMutationOptions<UpdateConnectedToMutation, UpdateConnectedToMutationVariables>;
export const CreateClassUserSessionDocument = gql`
    mutation createClassUserSession($input: ClassUserSessionInput) {
  createClassUserSession(input: $input) {
    classSlug
    userId
    sessionId
    connectionId
    connectedAt
    disconnectedAt
  }
}
    `;
export type CreateClassUserSessionMutationFn = Apollo.MutationFunction<CreateClassUserSessionMutation, CreateClassUserSessionMutationVariables>;

/**
 * __useCreateClassUserSessionMutation__
 *
 * To run a mutation, you first call `useCreateClassUserSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassUserSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassUserSessionMutation, { data, loading, error }] = useCreateClassUserSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClassUserSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassUserSessionMutation, CreateClassUserSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassUserSessionMutation, CreateClassUserSessionMutationVariables>(CreateClassUserSessionDocument, options);
      }
export type CreateClassUserSessionMutationHookResult = ReturnType<typeof useCreateClassUserSessionMutation>;
export type CreateClassUserSessionMutationResult = Apollo.MutationResult<CreateClassUserSessionMutation>;
export type CreateClassUserSessionMutationOptions = Apollo.BaseMutationOptions<CreateClassUserSessionMutation, CreateClassUserSessionMutationVariables>;
export const GetGroupsBySlugDocument = gql`
    query GetGroupsBySlug($slug: String!) {
  getGroupsBySlug(slug: $slug) {
    ...group
  }
}
    ${GroupFragmentDoc}`;

/**
 * __useGetGroupsBySlugQuery__
 *
 * To run a query within a React component, call `useGetGroupsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetGroupsBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetGroupsBySlugQuery, GetGroupsBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsBySlugQuery, GetGroupsBySlugQueryVariables>(GetGroupsBySlugDocument, options);
      }
export function useGetGroupsBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsBySlugQuery, GetGroupsBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsBySlugQuery, GetGroupsBySlugQueryVariables>(GetGroupsBySlugDocument, options);
        }
export type GetGroupsBySlugQueryHookResult = ReturnType<typeof useGetGroupsBySlugQuery>;
export type GetGroupsBySlugLazyQueryHookResult = ReturnType<typeof useGetGroupsBySlugLazyQuery>;
export type GetGroupsBySlugQueryResult = Apollo.QueryResult<GetGroupsBySlugQuery, GetGroupsBySlugQueryVariables>;
export const UpsertGroupDocument = gql`
    mutation UpsertGroup($input: GroupInput) {
  upsertGroup(input: $input) {
    ...group
  }
}
    ${GroupFragmentDoc}`;
export type UpsertGroupMutationFn = Apollo.MutationFunction<UpsertGroupMutation, UpsertGroupMutationVariables>;

/**
 * __useUpsertGroupMutation__
 *
 * To run a mutation, you first call `useUpsertGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertGroupMutation, { data, loading, error }] = useUpsertGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpsertGroupMutation, UpsertGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertGroupMutation, UpsertGroupMutationVariables>(UpsertGroupDocument, options);
      }
export type UpsertGroupMutationHookResult = ReturnType<typeof useUpsertGroupMutation>;
export type UpsertGroupMutationResult = Apollo.MutationResult<UpsertGroupMutation>;
export type UpsertGroupMutationOptions = Apollo.BaseMutationOptions<UpsertGroupMutation, UpsertGroupMutationVariables>;
export const CloseGroupDocument = gql`
    mutation closeGroup($input: GroupInput) {
  closeGroup(input: $input) {
    ...group
  }
}
    ${GroupFragmentDoc}`;
export type CloseGroupMutationFn = Apollo.MutationFunction<CloseGroupMutation, CloseGroupMutationVariables>;

/**
 * __useCloseGroupMutation__
 *
 * To run a mutation, you first call `useCloseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeGroupMutation, { data, loading, error }] = useCloseGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseGroupMutation(baseOptions?: Apollo.MutationHookOptions<CloseGroupMutation, CloseGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseGroupMutation, CloseGroupMutationVariables>(CloseGroupDocument, options);
      }
export type CloseGroupMutationHookResult = ReturnType<typeof useCloseGroupMutation>;
export type CloseGroupMutationResult = Apollo.MutationResult<CloseGroupMutation>;
export type CloseGroupMutationOptions = Apollo.BaseMutationOptions<CloseGroupMutation, CloseGroupMutationVariables>;
export const JoinGroupDocument = gql`
    mutation joinGroup($input: GroupUserInput) {
  joinGroup(input: $input) {
    ...group
  }
}
    ${GroupFragmentDoc}`;
export type JoinGroupMutationFn = Apollo.MutationFunction<JoinGroupMutation, JoinGroupMutationVariables>;

/**
 * __useJoinGroupMutation__
 *
 * To run a mutation, you first call `useJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGroupMutation, { data, loading, error }] = useJoinGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinGroupMutation(baseOptions?: Apollo.MutationHookOptions<JoinGroupMutation, JoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinGroupMutation, JoinGroupMutationVariables>(JoinGroupDocument, options);
      }
export type JoinGroupMutationHookResult = ReturnType<typeof useJoinGroupMutation>;
export type JoinGroupMutationResult = Apollo.MutationResult<JoinGroupMutation>;
export type JoinGroupMutationOptions = Apollo.BaseMutationOptions<JoinGroupMutation, JoinGroupMutationVariables>;
export const LeaveGroupDocument = gql`
    mutation leaveGroup($input: GroupUserInput) {
  leaveGroup(input: $input) {
    ...group
  }
}
    ${GroupFragmentDoc}`;
export type LeaveGroupMutationFn = Apollo.MutationFunction<LeaveGroupMutation, LeaveGroupMutationVariables>;

/**
 * __useLeaveGroupMutation__
 *
 * To run a mutation, you first call `useLeaveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveGroupMutation, { data, loading, error }] = useLeaveGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveGroupMutation(baseOptions?: Apollo.MutationHookOptions<LeaveGroupMutation, LeaveGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveGroupMutation, LeaveGroupMutationVariables>(LeaveGroupDocument, options);
      }
export type LeaveGroupMutationHookResult = ReturnType<typeof useLeaveGroupMutation>;
export type LeaveGroupMutationResult = Apollo.MutationResult<LeaveGroupMutation>;
export type LeaveGroupMutationOptions = Apollo.BaseMutationOptions<LeaveGroupMutation, LeaveGroupMutationVariables>;
export const UpdateRecordingDocument = gql`
    mutation UpdateRecording($input: RecordingInputUpdate) {
  updateRecording(input: $input) {
    ...recording
  }
}
    ${RecordingFragmentDoc}`;
export type UpdateRecordingMutationFn = Apollo.MutationFunction<UpdateRecordingMutation, UpdateRecordingMutationVariables>;

/**
 * __useUpdateRecordingMutation__
 *
 * To run a mutation, you first call `useUpdateRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecordingMutation, { data, loading, error }] = useUpdateRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecordingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecordingMutation, UpdateRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecordingMutation, UpdateRecordingMutationVariables>(UpdateRecordingDocument, options);
      }
export type UpdateRecordingMutationHookResult = ReturnType<typeof useUpdateRecordingMutation>;
export type UpdateRecordingMutationResult = Apollo.MutationResult<UpdateRecordingMutation>;
export type UpdateRecordingMutationOptions = Apollo.BaseMutationOptions<UpdateRecordingMutation, UpdateRecordingMutationVariables>;
export const GetMyClassUserStatusDocument = gql`
    subscription GetMyClassUserStatus($userId: String!) {
  getMyClassUserStatus(userId: $userId) {
    role
    schedule
    status
    userId
    handRaised
    connectionStatus
    inactive
    userMode
    isMobile
    location
    permissions
    User {
      firstName
      lastName
      profilePictureUrl
      id
    }
    Connections {
      firstName
      lastName
      profilePictureUrl
      id
      role
    }
  }
}
    `;

/**
 * __useGetMyClassUserStatusSubscription__
 *
 * To run a query within a React component, call `useGetMyClassUserStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClassUserStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClassUserStatusSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMyClassUserStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetMyClassUserStatusSubscription, GetMyClassUserStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetMyClassUserStatusSubscription, GetMyClassUserStatusSubscriptionVariables>(GetMyClassUserStatusDocument, options);
      }
export type GetMyClassUserStatusSubscriptionHookResult = ReturnType<typeof useGetMyClassUserStatusSubscription>;
export type GetMyClassUserStatusSubscriptionResult = Apollo.SubscriptionResult<GetMyClassUserStatusSubscription>;
export const GetUsersInLobbyDocument = gql`
    subscription getUsersInLobby($slug: String!) {
  getUsersInLobby(slug: $slug) {
    role
    schedule
    status
    userId
    userMode
    isMobile
    permissions
    userAgent
    os
    User {
      firstName
      lastName
      profilePictureUrl
      id
      role
      username
    }
  }
}
    `;

/**
 * __useGetUsersInLobbySubscription__
 *
 * To run a query within a React component, call `useGetUsersInLobbySubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersInLobbySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersInLobbySubscription({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetUsersInLobbySubscription(baseOptions: Apollo.SubscriptionHookOptions<GetUsersInLobbySubscription, GetUsersInLobbySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetUsersInLobbySubscription, GetUsersInLobbySubscriptionVariables>(GetUsersInLobbyDocument, options);
      }
export type GetUsersInLobbySubscriptionHookResult = ReturnType<typeof useGetUsersInLobbySubscription>;
export type GetUsersInLobbySubscriptionResult = Apollo.SubscriptionResult<GetUsersInLobbySubscription>;
export const GetStudentsInClassDocument = gql`
    subscription getStudentsInClass($slug: String!) {
  getStudentsInClass(slug: $slug) {
    role
    schedule
    status
    userId
    inactive
    permissions
    userAgent
    os
    User {
      firstName
      lastName
      profilePictureUrl
      id
      username
    }
    handRaised
    userMode
    isMobile
    connectionStatus
    Connections {
      firstName
      lastName
      profilePictureUrl
      id
      role
    }
  }
}
    `;

/**
 * __useGetStudentsInClassSubscription__
 *
 * To run a query within a React component, call `useGetStudentsInClassSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsInClassSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsInClassSubscription({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetStudentsInClassSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetStudentsInClassSubscription, GetStudentsInClassSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetStudentsInClassSubscription, GetStudentsInClassSubscriptionVariables>(GetStudentsInClassDocument, options);
      }
export type GetStudentsInClassSubscriptionHookResult = ReturnType<typeof useGetStudentsInClassSubscription>;
export type GetStudentsInClassSubscriptionResult = Apollo.SubscriptionResult<GetStudentsInClassSubscription>;
export const GetInstructorsInClassDocument = gql`
    subscription getInstructorsInClass($slug: String!) {
  getInstructorsInClass(slug: $slug) {
    role
    schedule
    status
    userId
    inactive
    userMode
    isMobile
    User {
      firstName
      lastName
      profilePictureUrl
      id
      username
    }
    Connections {
      firstName
      lastName
      profilePictureUrl
      id
      role
    }
  }
}
    `;

/**
 * __useGetInstructorsInClassSubscription__
 *
 * To run a query within a React component, call `useGetInstructorsInClassSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorsInClassSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorsInClassSubscription({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetInstructorsInClassSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetInstructorsInClassSubscription, GetInstructorsInClassSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetInstructorsInClassSubscription, GetInstructorsInClassSubscriptionVariables>(GetInstructorsInClassDocument, options);
      }
export type GetInstructorsInClassSubscriptionHookResult = ReturnType<typeof useGetInstructorsInClassSubscription>;
export type GetInstructorsInClassSubscriptionResult = Apollo.SubscriptionResult<GetInstructorsInClassSubscription>;
export const GetAllGroupsBySlugDocument = gql`
    subscription getAllGroupsBySlug($classSlug: String!, $userId: Int, $role: String) {
  getGroupsBySlug(classSlug: $classSlug, userId: $userId, role: $role) {
    id
    ownerId
    status
    whiteBoardId
    classSlug
    groupName
    members {
      firstName
      lastName
      profilePictureUrl
      id
      GroupUserStatus {
        status
      }
    }
  }
}
    `;

/**
 * __useGetAllGroupsBySlugSubscription__
 *
 * To run a query within a React component, call `useGetAllGroupsBySlugSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupsBySlugSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroupsBySlugSubscription({
 *   variables: {
 *      classSlug: // value for 'classSlug'
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useGetAllGroupsBySlugSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetAllGroupsBySlugSubscription, GetAllGroupsBySlugSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetAllGroupsBySlugSubscription, GetAllGroupsBySlugSubscriptionVariables>(GetAllGroupsBySlugDocument, options);
      }
export type GetAllGroupsBySlugSubscriptionHookResult = ReturnType<typeof useGetAllGroupsBySlugSubscription>;
export type GetAllGroupsBySlugSubscriptionResult = Apollo.SubscriptionResult<GetAllGroupsBySlugSubscription>;
export const GetViewerDocument = gql`
    query getViewer {
  getViewer {
    firstName
    lastName
    id
    role
    username
    centerCode
    verified
    profilePictureUrl
  }
}
    `;

/**
 * __useGetViewerQuery__
 *
 * To run a query within a React component, call `useGetViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewerQuery(baseOptions?: Apollo.QueryHookOptions<GetViewerQuery, GetViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViewerQuery, GetViewerQueryVariables>(GetViewerDocument, options);
      }
export function useGetViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViewerQuery, GetViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViewerQuery, GetViewerQueryVariables>(GetViewerDocument, options);
        }
export type GetViewerQueryHookResult = ReturnType<typeof useGetViewerQuery>;
export type GetViewerLazyQueryHookResult = ReturnType<typeof useGetViewerLazyQuery>;
export type GetViewerQueryResult = Apollo.QueryResult<GetViewerQuery, GetViewerQueryVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails {
  getUsersDetails {
    firstName
    lastName
    id
    role
    username
    profilePictureUrl
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const GetUserDetailsByIdDocument = gql`
    query GetUserDetailsById($id: ID!) {
  getUserDetail(id: $id) {
    firstName
    lastName
    id
    role
    username
    profilePictureUrl
    centerCode
  }
}
    `;

/**
 * __useGetUserDetailsByIdQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsByIdQuery, GetUserDetailsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsByIdQuery, GetUserDetailsByIdQueryVariables>(GetUserDetailsByIdDocument, options);
      }
export function useGetUserDetailsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsByIdQuery, GetUserDetailsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsByIdQuery, GetUserDetailsByIdQueryVariables>(GetUserDetailsByIdDocument, options);
        }
export type GetUserDetailsByIdQueryHookResult = ReturnType<typeof useGetUserDetailsByIdQuery>;
export type GetUserDetailsByIdLazyQueryHookResult = ReturnType<typeof useGetUserDetailsByIdLazyQuery>;
export type GetUserDetailsByIdQueryResult = Apollo.QueryResult<GetUserDetailsByIdQuery, GetUserDetailsByIdQueryVariables>;
export const GetUserLimitedDetailsByIdDocument = gql`
    query GetUserLimitedDetailsById($id: ID!) {
  getUserLimitedDetail(id: $id) {
    id
    role
    centerCode
  }
}
    `;

/**
 * __useGetUserLimitedDetailsByIdQuery__
 *
 * To run a query within a React component, call `useGetUserLimitedDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLimitedDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLimitedDetailsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserLimitedDetailsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserLimitedDetailsByIdQuery, GetUserLimitedDetailsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLimitedDetailsByIdQuery, GetUserLimitedDetailsByIdQueryVariables>(GetUserLimitedDetailsByIdDocument, options);
      }
export function useGetUserLimitedDetailsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLimitedDetailsByIdQuery, GetUserLimitedDetailsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLimitedDetailsByIdQuery, GetUserLimitedDetailsByIdQueryVariables>(GetUserLimitedDetailsByIdDocument, options);
        }
export type GetUserLimitedDetailsByIdQueryHookResult = ReturnType<typeof useGetUserLimitedDetailsByIdQuery>;
export type GetUserLimitedDetailsByIdLazyQueryHookResult = ReturnType<typeof useGetUserLimitedDetailsByIdLazyQuery>;
export type GetUserLimitedDetailsByIdQueryResult = Apollo.QueryResult<GetUserLimitedDetailsByIdQuery, GetUserLimitedDetailsByIdQueryVariables>;
export const GetViewerFromTokenDocument = gql`
    query GetViewerFromToken($token: String!) {
  getViewerFromToken(token: $token) {
    firstName
    lastName
    id
    role
    username
    centerCode
    verified
    profilePictureUrl
  }
}
    `;

/**
 * __useGetViewerFromTokenQuery__
 *
 * To run a query within a React component, call `useGetViewerFromTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerFromTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerFromTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetViewerFromTokenQuery(baseOptions: Apollo.QueryHookOptions<GetViewerFromTokenQuery, GetViewerFromTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetViewerFromTokenQuery, GetViewerFromTokenQueryVariables>(GetViewerFromTokenDocument, options);
      }
export function useGetViewerFromTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetViewerFromTokenQuery, GetViewerFromTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetViewerFromTokenQuery, GetViewerFromTokenQueryVariables>(GetViewerFromTokenDocument, options);
        }
export type GetViewerFromTokenQueryHookResult = ReturnType<typeof useGetViewerFromTokenQuery>;
export type GetViewerFromTokenLazyQueryHookResult = ReturnType<typeof useGetViewerFromTokenLazyQuery>;
export type GetViewerFromTokenQueryResult = Apollo.QueryResult<GetViewerFromTokenQuery, GetViewerFromTokenQueryVariables>;
export const LoginUserDocument = gql`
    query loginUser($username: String!, $password: String!, $classSlug: String) {
  login(username: $username, password: $password, classSlug: $classSlug) {
    id
    username
    firstName
    lastName
    centerCode
    uuid
    verified
    role
    token
    error
    profilePictureUrl
    timezone
    availableClasses {
      name
      slug
      centerCode
    }
    backendContainerIp
    tokenExp
  }
}
    `;

/**
 * __useLoginUserQuery__
 *
 * To run a query within a React component, call `useLoginUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      classSlug: // value for 'classSlug'
 *   },
 * });
 */
export function useLoginUserQuery(baseOptions: Apollo.QueryHookOptions<LoginUserQuery, LoginUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginUserQuery, LoginUserQueryVariables>(LoginUserDocument, options);
      }
export function useLoginUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginUserQuery, LoginUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginUserQuery, LoginUserQueryVariables>(LoginUserDocument, options);
        }
export type LoginUserQueryHookResult = ReturnType<typeof useLoginUserQuery>;
export type LoginUserLazyQueryHookResult = ReturnType<typeof useLoginUserLazyQuery>;
export type LoginUserQueryResult = Apollo.QueryResult<LoginUserQuery, LoginUserQueryVariables>;
export const ResetToCustomPasswordDocument = gql`
    mutation resetToCustomPassword($id: ID!, $password: String!, $verified: Boolean!) {
  resetToCustomPassword(id: $id, password: $password, verified: $verified) {
    firstName
    lastName
    role
    centerCode
    username
    uuid
    id
    verified
  }
}
    `;
export type ResetToCustomPasswordMutationFn = Apollo.MutationFunction<ResetToCustomPasswordMutation, ResetToCustomPasswordMutationVariables>;

/**
 * __useResetToCustomPasswordMutation__
 *
 * To run a mutation, you first call `useResetToCustomPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetToCustomPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetToCustomPasswordMutation, { data, loading, error }] = useResetToCustomPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useResetToCustomPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetToCustomPasswordMutation, ResetToCustomPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetToCustomPasswordMutation, ResetToCustomPasswordMutationVariables>(ResetToCustomPasswordDocument, options);
      }
export type ResetToCustomPasswordMutationHookResult = ReturnType<typeof useResetToCustomPasswordMutation>;
export type ResetToCustomPasswordMutationResult = Apollo.MutationResult<ResetToCustomPasswordMutation>;
export type ResetToCustomPasswordMutationOptions = Apollo.BaseMutationOptions<ResetToCustomPasswordMutation, ResetToCustomPasswordMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $firstName: String, $lastName: String, $username: String, $password: String, $role: String, $verified: Boolean, $image: String) {
  updateUser(
    id: $id
    firstName: $firstName
    lastName: $lastName
    username: $username
    password: $password
    role: $role
    verified: $verified
    image: $image
  ) {
    firstName
    lastName
    role
    username
    id
    verified
    profilePictureUrl
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *      verified: // value for 'verified'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const StartArchiveDocument = gql`
    query startArchive($archiveName: String!, $sessionId: String!, $streamId: String!, $instructorId: Int!, $studentId: Int!, $centerCode: String!, $isBackgroundOff: Boolean!, $instructorShared: Boolean!, $studentShared: Boolean!) {
  startArchive(
    archiveName: $archiveName
    sessionId: $sessionId
    streamId: $streamId
    instructorId: $instructorId
    studentId: $studentId
    centerCode: $centerCode
    isBackgroundOff: $isBackgroundOff
    instructorShared: $instructorShared
    studentShared: $studentShared
  ) {
    archiveId
    studentId
    recordingId
  }
}
    `;

/**
 * __useStartArchiveQuery__
 *
 * To run a query within a React component, call `useStartArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartArchiveQuery({
 *   variables: {
 *      archiveName: // value for 'archiveName'
 *      sessionId: // value for 'sessionId'
 *      streamId: // value for 'streamId'
 *      instructorId: // value for 'instructorId'
 *      studentId: // value for 'studentId'
 *      centerCode: // value for 'centerCode'
 *      isBackgroundOff: // value for 'isBackgroundOff'
 *      instructorShared: // value for 'instructorShared'
 *      studentShared: // value for 'studentShared'
 *   },
 * });
 */
export function useStartArchiveQuery(baseOptions: Apollo.QueryHookOptions<StartArchiveQuery, StartArchiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StartArchiveQuery, StartArchiveQueryVariables>(StartArchiveDocument, options);
      }
export function useStartArchiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StartArchiveQuery, StartArchiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StartArchiveQuery, StartArchiveQueryVariables>(StartArchiveDocument, options);
        }
export type StartArchiveQueryHookResult = ReturnType<typeof useStartArchiveQuery>;
export type StartArchiveLazyQueryHookResult = ReturnType<typeof useStartArchiveLazyQuery>;
export type StartArchiveQueryResult = Apollo.QueryResult<StartArchiveQuery, StartArchiveQueryVariables>;
export const StopArchiveDocument = gql`
    query stopArchive($archiveId: String!, $streamId: String!) {
  stopArchive(archiveId: $archiveId, streamId: $streamId) {
    archiveId
    studentId
  }
}
    `;

/**
 * __useStopArchiveQuery__
 *
 * To run a query within a React component, call `useStopArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useStopArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStopArchiveQuery({
 *   variables: {
 *      archiveId: // value for 'archiveId'
 *      streamId: // value for 'streamId'
 *   },
 * });
 */
export function useStopArchiveQuery(baseOptions: Apollo.QueryHookOptions<StopArchiveQuery, StopArchiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StopArchiveQuery, StopArchiveQueryVariables>(StopArchiveDocument, options);
      }
export function useStopArchiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StopArchiveQuery, StopArchiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StopArchiveQuery, StopArchiveQueryVariables>(StopArchiveDocument, options);
        }
export type StopArchiveQueryHookResult = ReturnType<typeof useStopArchiveQuery>;
export type StopArchiveLazyQueryHookResult = ReturnType<typeof useStopArchiveLazyQuery>;
export type StopArchiveQueryResult = Apollo.QueryResult<StopArchiveQuery, StopArchiveQueryVariables>;
export const AddStreamDocument = gql`
    query addStream($archiveId: String!, $streamId: String!, $type: Int!) {
  addStream(archiveId: $archiveId, streamId: $streamId, type: $type) {
    archiveId
  }
}
    `;

/**
 * __useAddStreamQuery__
 *
 * To run a query within a React component, call `useAddStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddStreamQuery({
 *   variables: {
 *      archiveId: // value for 'archiveId'
 *      streamId: // value for 'streamId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddStreamQuery(baseOptions: Apollo.QueryHookOptions<AddStreamQuery, AddStreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddStreamQuery, AddStreamQueryVariables>(AddStreamDocument, options);
      }
export function useAddStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddStreamQuery, AddStreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddStreamQuery, AddStreamQueryVariables>(AddStreamDocument, options);
        }
export type AddStreamQueryHookResult = ReturnType<typeof useAddStreamQuery>;
export type AddStreamLazyQueryHookResult = ReturnType<typeof useAddStreamLazyQuery>;
export type AddStreamQueryResult = Apollo.QueryResult<AddStreamQuery, AddStreamQueryVariables>;
export const MakeActiveUserCenterDocument = gql`
    mutation makeActiveUserCenter($id: String!, $centerCode: String) {
  makeActiveUserCenter(centerCode: $centerCode, id: $id) {
    id
    username
    firstName
    lastName
    centerCode
    uuid
    verified
    role
    token
    error
    profilePictureUrl
    timezone
    availableClasses {
      name
      slug
      centerCode
    }
  }
}
    `;
export type MakeActiveUserCenterMutationFn = Apollo.MutationFunction<MakeActiveUserCenterMutation, MakeActiveUserCenterMutationVariables>;

/**
 * __useMakeActiveUserCenterMutation__
 *
 * To run a mutation, you first call `useMakeActiveUserCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeActiveUserCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeActiveUserCenterMutation, { data, loading, error }] = useMakeActiveUserCenterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      centerCode: // value for 'centerCode'
 *   },
 * });
 */
export function useMakeActiveUserCenterMutation(baseOptions?: Apollo.MutationHookOptions<MakeActiveUserCenterMutation, MakeActiveUserCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeActiveUserCenterMutation, MakeActiveUserCenterMutationVariables>(MakeActiveUserCenterDocument, options);
      }
export type MakeActiveUserCenterMutationHookResult = ReturnType<typeof useMakeActiveUserCenterMutation>;
export type MakeActiveUserCenterMutationResult = Apollo.MutationResult<MakeActiveUserCenterMutation>;
export type MakeActiveUserCenterMutationOptions = Apollo.BaseMutationOptions<MakeActiveUserCenterMutation, MakeActiveUserCenterMutationVariables>;
export const GenerateTempTokenDocument = gql`
    mutation generateTempToken($id: ID!) {
  generateTempToken(id: $id)
}
    `;
export type GenerateTempTokenMutationFn = Apollo.MutationFunction<GenerateTempTokenMutation, GenerateTempTokenMutationVariables>;

/**
 * __useGenerateTempTokenMutation__
 *
 * To run a mutation, you first call `useGenerateTempTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTempTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTempTokenMutation, { data, loading, error }] = useGenerateTempTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateTempTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTempTokenMutation, GenerateTempTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTempTokenMutation, GenerateTempTokenMutationVariables>(GenerateTempTokenDocument, options);
      }
export type GenerateTempTokenMutationHookResult = ReturnType<typeof useGenerateTempTokenMutation>;
export type GenerateTempTokenMutationResult = Apollo.MutationResult<GenerateTempTokenMutation>;
export type GenerateTempTokenMutationOptions = Apollo.BaseMutationOptions<GenerateTempTokenMutation, GenerateTempTokenMutationVariables>;
export const VerifyTempTokenDocument = gql`
    mutation verifyTempToken($temp_id: String!) {
  verifyTempToken(temp_id: $temp_id) {
    id
    username
    firstName
    lastName
    centerCode
    uuid
    verified
    role
    token
    error
    profilePictureUrl
    timezone
    availableClasses {
      name
      slug
      centerCode
    }
    backendContainerIp
  }
}
    `;
export type VerifyTempTokenMutationFn = Apollo.MutationFunction<VerifyTempTokenMutation, VerifyTempTokenMutationVariables>;

/**
 * __useVerifyTempTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTempTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTempTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTempTokenMutation, { data, loading, error }] = useVerifyTempTokenMutation({
 *   variables: {
 *      temp_id: // value for 'temp_id'
 *   },
 * });
 */
export function useVerifyTempTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTempTokenMutation, VerifyTempTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTempTokenMutation, VerifyTempTokenMutationVariables>(VerifyTempTokenDocument, options);
      }
export type VerifyTempTokenMutationHookResult = ReturnType<typeof useVerifyTempTokenMutation>;
export type VerifyTempTokenMutationResult = Apollo.MutationResult<VerifyTempTokenMutation>;
export type VerifyTempTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTempTokenMutation, VerifyTempTokenMutationVariables>;
export const UpdateTokenDocument = gql`
    mutation updateToken {
  updateToken {
    token
    tokenExp
  }
}
    `;
export type UpdateTokenMutationFn = Apollo.MutationFunction<UpdateTokenMutation, UpdateTokenMutationVariables>;

/**
 * __useUpdateTokenMutation__
 *
 * To run a mutation, you first call `useUpdateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokenMutation, { data, loading, error }] = useUpdateTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTokenMutation, UpdateTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTokenMutation, UpdateTokenMutationVariables>(UpdateTokenDocument, options);
      }
export type UpdateTokenMutationHookResult = ReturnType<typeof useUpdateTokenMutation>;
export type UpdateTokenMutationResult = Apollo.MutationResult<UpdateTokenMutation>;
export type UpdateTokenMutationOptions = Apollo.BaseMutationOptions<UpdateTokenMutation, UpdateTokenMutationVariables>;
export const GetUserDevicesDocument = gql`
    query GetUserDevices($userId: String!) {
  getUserDevices(userId: $userId) {
    userId
    devices {
      id
      name
      type
      selected
    }
  }
}
    `;

/**
 * __useGetUserDevicesQuery__
 *
 * To run a query within a React component, call `useGetUserDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDevicesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDevicesQuery(baseOptions: Apollo.QueryHookOptions<GetUserDevicesQuery, GetUserDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDevicesQuery, GetUserDevicesQueryVariables>(GetUserDevicesDocument, options);
      }
export function useGetUserDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDevicesQuery, GetUserDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDevicesQuery, GetUserDevicesQueryVariables>(GetUserDevicesDocument, options);
        }
export type GetUserDevicesQueryHookResult = ReturnType<typeof useGetUserDevicesQuery>;
export type GetUserDevicesLazyQueryHookResult = ReturnType<typeof useGetUserDevicesLazyQuery>;
export type GetUserDevicesQueryResult = Apollo.QueryResult<GetUserDevicesQuery, GetUserDevicesQueryVariables>;
export const UpdateUserDevicesDocument = gql`
    mutation UpdateUserDevices($input: UserDeviceInput) {
  updateUserDevices(input: $input) {
    devices {
      id
      name
      type
      selected
    }
    userId
  }
}
    `;
export type UpdateUserDevicesMutationFn = Apollo.MutationFunction<UpdateUserDevicesMutation, UpdateUserDevicesMutationVariables>;

/**
 * __useUpdateUserDevicesMutation__
 *
 * To run a mutation, you first call `useUpdateUserDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDevicesMutation, { data, loading, error }] = useUpdateUserDevicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserDevicesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserDevicesMutation, UpdateUserDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserDevicesMutation, UpdateUserDevicesMutationVariables>(UpdateUserDevicesDocument, options);
      }
export type UpdateUserDevicesMutationHookResult = ReturnType<typeof useUpdateUserDevicesMutation>;
export type UpdateUserDevicesMutationResult = Apollo.MutationResult<UpdateUserDevicesMutation>;
export type UpdateUserDevicesMutationOptions = Apollo.BaseMutationOptions<UpdateUserDevicesMutation, UpdateUserDevicesMutationVariables>;
export const GetActiveBoardDocument = gql`
    query getActiveBoard($userId: String, $centerCode: String, $connectorId: String, $classSlug: String) {
  filterWhiteBoards(
    filter: {userId: $userId, status: "active", centerCode: $centerCode, connectorId: $connectorId, classSlug: $classSlug}
  ) {
    status
    userId
    whiteBoardId
    pdfUrl
    whiteBoardName
    createdAt
    updatedAt
    isCreated
    User {
      firstName
      lastName
    }
    WhiteBoardServer {
      address
    }
    ProcessedPDF {
      fileName
      whiteboardName
      studentId
      ProcessedPDFDetails {
        pageNumber
        url
      }
    }
  }
}
    `;

/**
 * __useGetActiveBoardQuery__
 *
 * To run a query within a React component, call `useGetActiveBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveBoardQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      centerCode: // value for 'centerCode'
 *      connectorId: // value for 'connectorId'
 *      classSlug: // value for 'classSlug'
 *   },
 * });
 */
export function useGetActiveBoardQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveBoardQuery, GetActiveBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveBoardQuery, GetActiveBoardQueryVariables>(GetActiveBoardDocument, options);
      }
export function useGetActiveBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveBoardQuery, GetActiveBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveBoardQuery, GetActiveBoardQueryVariables>(GetActiveBoardDocument, options);
        }
export type GetActiveBoardQueryHookResult = ReturnType<typeof useGetActiveBoardQuery>;
export type GetActiveBoardLazyQueryHookResult = ReturnType<typeof useGetActiveBoardLazyQuery>;
export type GetActiveBoardQueryResult = Apollo.QueryResult<GetActiveBoardQuery, GetActiveBoardQueryVariables>;